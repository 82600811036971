import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'

import KanseiButton from 'components/button'
import KanseiSimpleSelectBox from 'components/simpleSelectBox'
import { companyApi } from 'hooks/api/company'
import { Option } from 'types/option'
import { Security } from 'types/secuirty'

export interface BranchForm {
  branchName: string
  address: string
  phoneNumber: string
  faxNumber: string
  plugins: number[]
}

export interface UserForm {
  id?: string
  loginId: string
  name: string
  email: string
}

const options: Option[] = [
  {
    label: '思考性アンケートver1',
    value: 100,
  },
]

export default function SecurityComponent(props: { branchId: string }) {
  const [securities, setSecurities] = useState<Security[]>()
  const [selectAction, setSelectAction] = useState<string>()
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [selectedSecurityIds, setSelectedSecurityIds] = useState<string[]>([])

  useEffect(() => {
    companyApi()
      .filterSecurityById(props.branchId)
      .then((res) => {
        setSecurities(res.data)
      })
  }, [props.branchId])

  const handleToggle = (securityId: string) => {
    setSelectedSecurityIds((prevSelected) =>
      prevSelected.includes(securityId)
        ? prevSelected.filter((id) => id !== securityId)
        : [...prevSelected, securityId],
    )
  }

  const handleSelectAction = (value: string) => {
    setSelectAction(value)
  }

  // ヘッダーの全選択チェックボックスの処理
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedSecurityIds(
        securities ? securities.map((security) => security.id!) : [],
      )
    } else {
      setSelectedSecurityIds([])
    }
  }

  // 全件選択の判定
  const allSelected =
    securities &&
    securities.length > 0 &&
    selectedSecurityIds.length === securities.length
  // 部分選択の判定
  const someSelected =
    securities &&
    securities.length > 0 &&
    selectedSecurityIds.length > 0 &&
    selectedSecurityIds.length < securities.length

  const onSubmit = () => {
    const label = options.find((x) => x.value === selectAction)?.label
    const message = `${selectedSecurityIds.length} 人に "${label}" を送ろうとしています。\n実行する場合は"実行"と入力してください。`
    const input = window.prompt(message, '')
    if (input === '実行') {
      setSubmitted(true)
      companyApi()
        .notifySecurity(
          props.branchId,
          selectedSecurityIds,
          Number(selectAction),
        )
        .then(() => {
          alert('送信しました')
        })
        .catch((error) => {
          alert(error.data.msg)
        })
    }
  }

  return (
    <Box>
      {/* 警備員情報 */}
      <Typography sx={{ margin: '10px 0' }}>警備員情報情報</Typography>
      <KanseiSimpleSelectBox
        style={{ width: '250px' }}
        options={options}
        value={selectAction}
        onChange={handleSelectAction}
      ></KanseiSimpleSelectBox>
      <KanseiButton
        onClick={onSubmit}
        style={{ marginLeft: '10px' }}
        disabled={submitted}
      >
        アクション
      </KanseiButton>
      <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={Boolean(someSelected)}
                  checked={Boolean(allSelected)}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>名前</TableCell>
              <TableCell>年齢</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {securities &&
              securities.map((security) => (
                <TableRow key={security.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedSecurityIds.includes(security.id!)}
                      onChange={() => handleToggle(security.id!)}
                    />
                  </TableCell>
                  <TableCell>
                    {security.lastName + security.firstName}
                  </TableCell>
                  <TableCell>{security.age}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
