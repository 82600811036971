import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { CSSProperties } from 'react'

import { Option } from 'types/option'

interface Props {
  options: Option[]
  onChange: (v: string) => void
  value?: string
  style?: CSSProperties
  disabled?: boolean
  multiple?: boolean
  required?: boolean
  fullWidth?: boolean
  onBlur?: () => void
}

export default function KanseiSimpleSelectBox(props: Props) {
  const {
    options,
    value = '',
    style,
    disabled = false,
    multiple = false,
    required = false,
    fullWidth = false,
    onBlur,
    onChange,
  } = props

  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value as string)
  }

  return (
    <Select
      required={required}
      value={value}
      sx={{ ...style }}
      onBlur={onBlur}
      multiple={multiple}
      disabled={disabled}
      onChange={handleChange}
      fullWidth={fullWidth}
    >
      {options.map((option) => {
        return (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        )
      })}
    </Select>
  )
}
