import { AxiosResponse } from 'axios'

import { Company } from 'types/company'
import { CompanyBranch } from 'types/companyBranch'
import { Security } from 'types/secuirty'

import { _axios } from './axios'

const ALL = `/companies`
const FIND_BY_ID = (id: string) => `/companies/${id}`
const FIND_BRANCH_BY_ID = (id: string) => `/company-branches/${id}`
const FILTER_SECURITY_BY_ID = (id: string) =>
  `/company-branches/${id}/securities`
const ADD = `/companies`
const ADD_BRANCH = `/company-branches`
const EDIT_BRANCH = (id: string) => `/company-branches/${id}`
const EDIT = (id: string) => `/companies/${id}`
const NOTIFICATION_SECURITY = (id: string) =>
  `/company-branches/${id}/securities/notifications`

export const companyApi = () => {
  const getAll = async (): Promise<AxiosResponse<Company[]>> => {
    return await _axios()
      .get(ALL)
      .then((res) => {
        res.data = res.data.companies
        return res
      })
  }

  const findById = async (id: string): Promise<AxiosResponse<Company>> => {
    return await _axios()
      .get(FIND_BY_ID(id))
      .then((res) => {
        res.data = res.data.company
        return res
      })
  }

  const findBranchById = async (
    branchId: string,
  ): Promise<AxiosResponse<CompanyBranch>> => {
    return await _axios()
      .get(FIND_BRANCH_BY_ID(branchId))
      .then((res) => {
        res.data = res.data.companyBranch
        return res
      })
  }

  const filterSecurityById = async (
    branchId: string,
  ): Promise<AxiosResponse<Security[]>> => {
    return await _axios()
      .get(FILTER_SECURITY_BY_ID(branchId))
      .then((res) => {
        res.data = res.data.securities
        return res
      })
  }

  const add = async (data: Company): Promise<AxiosResponse<Company[]>> => {
    return await _axios()
      .post(ADD, data)
      .then((res) => {
        res.data = res.data
        return res
      })
  }

  const addBranch = async (
    data: CompanyBranch,
  ): Promise<AxiosResponse<any>> => {
    return await _axios()
      .post(ADD_BRANCH, data)
      .then((res) => {
        res.data = res.data
        return res
      })
  }

  const editBranch = async (
    id: string,
    data: CompanyBranch,
  ): Promise<AxiosResponse<any>> => {
    return await _axios()
      .put(EDIT_BRANCH(id), data)
      .then((res) => {
        res.data = res.data
        return res
      })
  }

  const edit = async (
    id: string,
    data: Company,
  ): Promise<AxiosResponse<Company[]>> => {
    return await _axios()
      .put(EDIT(id), data)
      .then((res) => {
        res.data = res.data
        return res
      })
  }

  const notifySecurity = async (
    branchId: string,
    securityIds: string[],
    notificationType: number,
  ): Promise<AxiosResponse<Security[]>> => {
    const params = {
      securityIds: securityIds,
      notificationType: notificationType,
    }
    return await _axios()
      .post(NOTIFICATION_SECURITY(branchId), params)
      .then((res) => {
        return res
      })
  }

  return {
    getAll,
    findById,
    findBranchById,
    filterSecurityById,
    add,
    addBranch,
    editBranch,
    edit,
    notifySecurity,
  }
}
